import React from 'react';
import Banner from './Banner';
import Footer from './Footer';
import { useNavigate } from 'react-router-dom';

function DemoEasyApp() {
  const navigate = useNavigate();

  return (
    <div className="flex flex-col min-h-screen">
      <Banner />
      
      {/* Sección 1: Video */}
      <section className="w-full bg-white py-16">
        <div className="max-w-screen-xl mx-auto">
          <h2 className="text-3xl font-bold mb-8 text-[#0f2645]">EasyApp Demo</h2>
          <div className="w-full aspect-video rounded-2xl overflow-hidden shadow-lg">
            <video 
              className="w-full h-full object-cover"
              controls
              autoPlay={true}
            >
              <source src="/videoeasyappdemo.mp4" type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
        </div>
      </section>

      {/* Sección 6: Cómo funciona */}
      <section className="w-full bg-gray-50 py-16">
        <div className="max-w-screen-xl mx-auto">
          <h2 className="text-3xl font-bold mb-8 text-[#0f2645]">About EasyApp</h2>
          
          <div className="space-y-8">
            <div className="bg-white p-8 rounded-xl shadow-sm">
              <p className="text-lg text-gray-700 mb-6">
                EasyApp is the perfect solution for Zendesk administrators looking to quickly and easily integrate external systems, such as Salesforce, Hubspot, Monday, Zoho, etc., any CRM or API-enabled system. With an intuitive interface, EasyApp allows you to set up API calls in just four steps, and thus display relevant data directly in the Zendesk ticket creation interface to agents.
              </p>

              <p className="text-lg text-gray-700 mb-8">
                By being able to integrate your systems so easily with Zendesk, EasyApp will also allow you to improve your agents' efficiency by providing them with the information they need, without the need to open more tabs in their browser, resulting in a 360 agent experience and better customer service.
              </p>

              <div className="mb-8">
                <h3 className="text-xl font-semibold mb-4 text-[#0f2645]">Use Cases</h3>
                <p className="text-gray-700 mb-4">Use your own APIs and present relevant information to the Agent in the Ticket:</p>
                <ul className="space-y-3 text-gray-700">
                  <li className="flex items-start">
                    <span className="text-[#ffa100] mr-2">•</span>
                    Customer Data (CRM)
                  </li>
                  <li className="flex items-start">
                    <span className="text-[#ffa100] mr-2">•</span>
                    Check payment orders, charges, etc.
                  </li>
                  <li className="flex items-start">
                    <span className="text-[#ffa100] mr-2">•</span>
                    Order status or tracking
                  </li>
                  <li className="flex items-start">
                    <span className="text-[#ffa100] mr-2">•</span>
                    Articles from your external knowledge base
                  </li>
                  <li className="flex items-start">
                    <span className="text-[#ffa100] mr-2">•</span>
                    Get data from your API using Ticket attributes
                  </li>
                </ul>
              </div>

              <div className="bg-gray-50 p-6 rounded-lg">
                <p className="text-gray-700">
                  If you have any questions please contact us at{' '}
                  <a href="mailto:contacto@easyappforz.com" className="text-[#ffa100] hover:underline">
                    contacto@easyappforz.com
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Sección 7: Precios */}
      <section className="w-full bg-white py-16">
        <div className="max-w-screen-xl mx-auto">
          <h2 className="text-3xl font-bold mb-8 text-[#0f2645]">Pricing</h2>
          <div className="bg-gradient-to-r from-orange-50/30 to-transparent p-8 rounded-2xl shadow-sm">
            <div className="flex flex-col md:flex-row justify-between items-start md:items-center gap-6">
              <div>
                <h3 className="text-2xl font-bold text-[#0f2645] mb-2">Price (USD)</h3>
                <div className="flex items-baseline gap-2">
                  <span className="text-4xl font-bold text-[#0f2645]">$3.50</span>
                  <span className="text-gray-600">/agent/month</span>
                </div>
              </div>
              <div className="flex flex-col md:flex-row gap-6">
                <a 
                  href="https://www.zendesk.com/in/marketplace/apps/support/1104791/easyapp/?queryID=ef8c46b9f3fecd2ce6f85d079873c531"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="inline-block w-full md:w-auto px-8 py-3 bg-[#ffa100] text-white font-medium rounded-lg hover:bg-[#ff9100] transition-colors duration-300 text-center"
                >
                  Start Your Free Trial
                </a>
                <button 
                  onClick={() => navigate('/#contact')}
                  className="w-full md:w-auto px-8 py-3 bg-[#0f2645] text-white font-medium rounded-lg hover:bg-[#1c3861] transition-colors duration-300"
                >
                  Schedule Demo
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
}

export default DemoEasyApp; 