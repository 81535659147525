import React from 'react';
import Banner from './Banner';
import Footer from './Footer';

function TerminosYCondiciones() {
  return (
    <div className="flex flex-col min-h-screen">
      <Banner />
      <div className="flex-grow bg-gray-50">
        <div className="max-w-4xl mx-auto py-12 px-4">
          <h1 className="text-3xl font-bold mb-8">Terms and Conditions</h1>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4">1. Introduction</h2>
            <p className="text-gray-700 leading-relaxed">
              Welcome to EasyApp. These Terms and Conditions (hereinafter, "Terms") establish the legal terms governing the use of our Zendesk application. By installing and using EasyApp, you agree to comply with these Terms. If you disagree with any of these terms, we recommend not using our application.
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4">2. Service Description</h2>
            <p className="text-gray-700 leading-relaxed">
              EasyApp is an innovative solution designed for Zendesk administrators and agents, enabling the implementation of applications that make external API calls in just minutes, without requiring programming knowledge. Our tool guides users step by step to deploy an application alongside the ticket, allowing agents to view previously configured information through their API.
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4">3. Installation and Access</h2>
            <ul className="list-disc pl-6 text-gray-700 leading-relaxed">
              <li className="mb-2"><strong>Availability:</strong> EasyApp is exclusively available through the Zendesk Marketplace. We do not allow manual installations from other sources.</li>
              <li className="mb-2"><strong>Subscription and Payment:</strong> Access to EasyApp is obtained through a monthly subscription per agent per month per Zendesk instance, plus applicable taxes according to the region. We do not accept payments through additional means; the only available payment method is through Stripe.</li>
              <li className="mb-2"><strong>Alternative Payment Process:</strong> If a different payment method is required, we will notify the client directly through their registered email from our official email <a href="mailto:contacto@easyappforz.com" className="text-blue-600 hover:underline">contacto@easyappforz.com</a>.</li>
            </ul>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4">4. Data Security</h2>
            <ul className="list-disc pl-6 text-gray-700 leading-relaxed">
              <li className="mb-2"><strong>Data Protection:</strong> We do not store sensitive client data. API credentials configured by our clients are encrypted and stored in a proxy outside Zendesk to prevent unauthorized access.</li>
              <li className="mb-2"><strong>Restricted Access:</strong> We only allow calls to our proxy from Zendesk instances authorized by us.</li>
              <li className="mb-2"><strong>Security Policies:</strong> By accepting these Terms, the client assumes our secure data handling policies.</li>
              <li className="mb-2"><strong>No Third-Party Disclosure:</strong> We do not provide any type of data to third parties for profit or any other purpose.</li>
              <li className="mb-2"><strong>Fraud Protection:</strong> We will not request codes, confirmations, credentials, credit card numbers, or other sensitive data through channels other than Zendesk.</li>
            </ul>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4">5. Payments and Billing</h2>
            <ul className="list-disc pl-6 text-gray-700 leading-relaxed">
              <li className="mb-2">The application costs per agent per month per Zendesk instance, plus required taxes for the region.</li>
              <li className="mb-2">We do not accept payments through additional means. Only through Stripe.</li>
              <li className="mb-2">The subscription to our services is monthly and contracted directly through the application installation in Zendesk.</li>
            </ul>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4">6. Intellectual Property</h2>
            <p className="text-gray-700 leading-relaxed">
              All content, tools, and visual elements present in EasyApp, including text, images, logos, and software, are the exclusive property of EasyApp. Reproduction, distribution, or unauthorized use of such content is prohibited.
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4">7. Limitation of Liability</h2>
            <p className="text-gray-700 leading-relaxed">
              EasyApp is not responsible for direct, indirect, incidental, or consequential damages resulting from the use or inability to use the application. We strive to ensure the security and functionality of the application, but we do not guarantee that it will be error-free or function without interruptions.
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4">8. Modifications to Terms</h2>
            <p className="text-gray-700 leading-relaxed">
              We reserve the right to modify these Terms at any time. Modifications will be effective immediately after their publication in our application. It is the user's responsibility to periodically review these terms to stay informed of any changes.
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4">9. Termination</h2>
            <p className="text-gray-700 leading-relaxed">
              We may suspend or terminate access to EasyApp at any time, without prior notice, for breach of these Terms or for any other reason at our discretion.
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4">10. Applicable Law and Jurisdiction</h2>
            <p className="text-gray-700 leading-relaxed">
              These Terms are governed by the laws of the country where EasyApp is registered. Any dispute related to these terms will be subject to the exclusive jurisdiction of the competent courts in that jurisdiction.
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4">11. Contact</h2>
            <p className="text-gray-700 leading-relaxed">
              If you have any questions or need more information about these Terms and Conditions, please contact us via email at: <a href="mailto:contacto@easyappforz.com" className="text-blue-600 hover:underline">contacto@easyappforz.com</a>
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4">12. Consumer Right to Cancel</h2>
            <p className="text-gray-700 leading-relaxed">
              If you are a Consumer and unless the below exception applies, you have the right to cancel this Agreement and return the Product within 14 days without giving any reason. The cancellation period will expire after 14 days from the day after completion of the Transaction.
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4">13. Refund Policy</h2>
            <p className="text-gray-700 leading-relaxed mb-4">
              We offer a 14-day money-back guarantee for all our products. If you are not satisfied with your purchase, you can request a refund within 14 days of the initial purchase date. This refund policy applies to the initial subscription purchase only and not to subsequent renewal payments.
            </p>
            
            <p className="text-gray-700 leading-relaxed mb-4">
              To request a refund, please contact our support team at contacto@easyappforz.com. You do not need to provide a reason for requesting a refund within this 14-day period.
            </p>

            <p className="text-gray-700 leading-relaxed mb-4">
              Please note that this right to cancel and obtain a refund does not apply in the following circumstances:
            </p>

            <ul className="list-disc pl-8 mb-4 text-gray-700">
              <li className="mb-2">If you have actively used the product in your Zendesk instance beyond basic testing</li>
              <li className="mb-2">For subscription renewals (the refund right only applies to the initial purchase)</li>
              <li className="mb-2">If we find evidence of fraud, abuse, or other manipulative behavior</li>
            </ul>

            <p className="text-gray-700 leading-relaxed mb-4">
              For subscriptions, you may cancel your subscription at any time by contacting our support team. Cancellation will take effect at the end of the current billing period. We do not provide refunds for partial subscription periods or unused time on your subscription.
            </p>

            <p className="text-gray-700 leading-relaxed">
              This refund policy does not affect your statutory rights, including your right to receive a refund for products that are defective or not as described.
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4">14. Exception to the Right to Cancel</h2>
            <p className="text-gray-700 leading-relaxed">
              Your right as a Consumer to cancel your order does not apply to the supply of Digital Content that you have started to download, stream or otherwise acquire and to Products which you have had the benefit of.
            </p>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4">15. Effect of Cancellation</h2>
            <ul className="list-disc pl-6 text-gray-700 leading-relaxed">
              <li className="mb-2">If you cancel this Agreement as permitted above, we will reimburse to you all payments received from you.</li>
              <li className="mb-2">We will make the reimbursement without undue delay, and not later than 14 days after the day on which we are informed about your decision to cancel this Agreement.</li>
              <li className="mb-2">We will make the reimbursement using the same means of payment as you used for the initial transaction and you will not incur any fees as a result of the reimbursement.</li>
            </ul>
          </section>

          <section className="mb-8">
            <h2 className="text-2xl font-semibold mb-4">16. Privacy Policy</h2>
            <p className="text-gray-700 leading-relaxed">
              EasyApp will maintain appropriate administrative, physical, and technical safeguards for protection of the security, confidentiality and integrity of Buyer data. For more details about how we handle your data, please refer to our <a href="/privacy-policy" className="text-blue-600 hover:underline">Privacy Policy</a>.
            </p>
          </section>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default TerminosYCondiciones;
