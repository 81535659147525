import React, { useState } from 'react';
import { Link } from 'react-router-dom';

function Banner() {
  const [isOpenQuienesSomos, setIsOpenQuienesSomos] = useState(false);
  const [isOpenLoQueOfrecemos, setIsOpenLoQueOfrecemos] = useState(false);
  const [isOpenPreguntasFrecuentes, setIsOpenPreguntasFrecuentes] = useState(false);

  return (
    <section className="banner-container">
      <div className="flex items-center justify-between px-12 py-6">
        <div className="flex items-center">
          <div className="flex items-center space-x-6 mr-6 mt-1">
            <img 
              src="/badge01.png" 
              alt="Badge 1" 
              className="h-16 w-auto opacity-90 hover:opacity-100 transition-opacity"
            />
            <img 
              src="/badge02.png" 
              alt="Badge 2" 
              className="h-16 w-auto opacity-90 hover:opacity-100 transition-opacity"
            />
          </div>
          <div className="banner-logo text-4xl flex items-center">
            <span className="text-[#ffa100] font-bold">Easy</span>
            <span className="text-white font-bold">App</span>
            <span className="typewriter text-2xl ml-3">for Zendesk</span>
          </div>
        </div>
        
        <nav className="banner-nav flex items-center space-x-5 ml-32">
          <div className="banner-nav-item">
            <Link to="/" className="text-gray-300 hover:text-white transition-colors duration-200 text-base">
              Home
            </Link>
          </div>
          <div className="banner-nav-item">
            <Link to="/plans" className="text-gray-300 hover:text-white transition-colors duration-200 text-base">
              Pricing
            </Link>
          </div>
          <div className="banner-nav-item">
            <button 
              onClick={() => setIsOpenQuienesSomos(true)} 
              className="text-gray-300 hover:text-white transition-colors duration-200 text-base"
            >
              About Us
            </button>
          </div>
          <div className="banner-nav-item">
            <button 
              onClick={() => setIsOpenLoQueOfrecemos(true)} 
              className="text-gray-300 hover:text-white transition-colors duration-200 text-base"
            >
              What We Offer
            </button>
          </div>
        </nav>
      </div>

      {/* About Us Modal */}
      {isOpenQuienesSomos && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white text-black max-h-[80vh] max-w-2xl w-full overflow-y-auto p-6 rounded-lg shadow-lg">
            <h2 className="text-xl font-bold mb-4">About Us</h2>
            <p>
              We are a team with over 15 years of experience in CRM, Zendesk, Salesforce, Web Development, Cloud Architecture, CX, and general consulting. Throughout our career, we have executed CRM implementation projects and web applications for companies across different industries, as well as working on cloud telephony solutions, process optimization, and customer-centric experience design.
              <br></br><br></br>
              With more than 20 successfully implemented projects, we have decided to develop a new way of creating applications for Zendesk, which is especially useful for administrators and consultants of this platform, as it makes work simpler but effective by integrating and presenting information from external systems or APIs to agents within the Zendesk ticket.
            </p>
            <button className="mt-4 bg-[#ffa100] text-white py-2 px-4 rounded-md" onClick={() => setIsOpenQuienesSomos(false)}>
              Close
            </button>
          </div>
        </div>
      )}

      {/* What We Offer Modal */}
      {isOpenLoQueOfrecemos && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white text-black max-h-[80vh] max-w-2xl w-full overflow-y-auto p-6 rounded-lg shadow-lg">
            <h2 className="text-xl font-bold mb-4">What We Offer</h2>
            <p>
              EasyApp is an innovative solution that allows you to implement Zendesk applications in just minutes, without requiring programming knowledge. You only need a basic understanding of how to make API calls, and our tool will guide you step by step to deploy an application alongside the ticket. This way, agents can view the information you've previously configured using your API.
            </p><br/>
            <p>EasyApp is simple, versatile, and fast. Additionally, it ensures your data security: all information remains in your own Zendesk instance, without being stored in external databases.</p>
            <button className="mt-4 bg-[#ffa100] text-white py-2 px-4 rounded-md" onClick={() => setIsOpenLoQueOfrecemos(false)}>
              Close
            </button>
          </div>
        </div>
      )}
    </section>
  );
}

export default Banner;