import { useNavigate } from 'react-router-dom';

function VideoUnoAndTextSection() {
  const navigate = useNavigate();

  const apps = [
    {
      name: "EasyApp",
      description: "Connect your CRM, ERP or any API-enabled system to Zendesk in minutes without coding. Display relevant customer data, orders, and external information directly in your tickets. Perfect for enhancing agent productivity with a 360° customer view.",
      imageUrl: "/logoeasyapp.png",
      bgColor: "bg-gradient-to-r from-orange-50/30 to-transparent",
      demoPath: "/demo-easy-app",
      installUrl: "https://www.zendesk.com/in/marketplace/apps/support/1104791/easyapp/?queryID=ef8c46b9f3fecd2ce6f85d079873c531"
    },
    {
      name: "Ticket Times",
      description: "Track and analyze time spent on tickets by your agents. Get detailed insights into productivity metrics, identify bottlenecks, and optimize workload distribution. Perfect for improving team efficiency and time management in your customer support.",
      imageUrl: "/logotickettimes.png",
      bgColor: "bg-gradient-to-r from-purple-50/30 to-transparent",
      demoPath: "/demo-ticket-times",
      installUrl: "https://www.zendesk.com/in/marketplace/apps/support/1096052/ticket-times/?queryID=01b34d31bb3bf2164796a7b467ef519a"
    },
    {
      name: "Ticket Quality",
      description: "Get detailed insights into your ticket handling, SLA compliance, and team performance metrics. Analyze first response times, resolution times, and group transfers. Perfect for Administrators, Supervisors, and QA staff looking to enhance service quality.",
      imageUrl: "/logoticketquality.png",
      bgColor: "bg-gradient-to-r from-blue-50/30 to-transparent",
      demoPath: "/demo-ticket-quality",
      installUrl: "https://www.zendesk.com/in/marketplace/apps/support/1101694/ticket-quality/?queryID=2cb3df7d222420058e998070474fd325"
    }
  ];

  return (
    <section className="w-full py-16">
      <div className="max-w-screen-xl mx-auto">
        <h2 className="text-3xl font-bold text-center mb-3 text-[#0f2645]">Made by admins, for admins</h2>
        <p className="text-lg text-gray-600 text-center mb-16">
          Enhance your Zendesk experience with our powerful apps
        </p>
        
        <div className="space-y-12">
          {apps.map((app, index) => (
            <div 
              key={app.name}
              className={`flex flex-col md:flex-row items-start gap-4 p-8 rounded-2xl ${app.bgColor} shadow-sm hover:shadow-md transition-all duration-300`}
            >
              <div className="w-full md:w-1/4">
                <div className="h-44 w-44 flex items-center justify-start">
                  <img
                    src={app.imageUrl}
                    alt={`${app.name} logo`}
                    className="max-h-full max-w-full object-contain"
                  />
                </div>
              </div>
              
              <div className="w-full md:w-3/4 -ml-4">
                <h3 className="text-2xl font-bold mb-4 text-[#0f2645]">
                  {app.name}
                </h3>
                
                <p className="text-gray-700 leading-relaxed text-base mb-6 pr-12">
                  {app.description}
                </p>
                
                <div className="flex gap-4">
                  <a 
                    href={app.installUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="px-6 py-2.5 rounded-lg bg-[#ffa100] text-white font-medium hover:bg-[#ff9100] transition-colors duration-300"
                  >
                    Install in Zendesk
                  </a>
                  <button 
                    className="px-6 py-2.5 rounded-lg bg-[#0f2645] text-white font-medium hover:bg-[#1c3861] transition-colors duration-300"
                    onClick={() => navigate(app.demoPath)}
                  >
                    Watch Demo
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default VideoUnoAndTextSection;
