import { useRef } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Banner from './components/Banner';
import VideoUnoAndTextSection from './components/VideoUnoAndTextSection';
import FormSuscribe from './components/FormSuscribe';
import Footer from './components/Footer';
import TerminosYCondiciones from './components/TerminosYCondiciones';
import Subscription from './components/Subscription';
import Plans from './components/Plans';
import PrivacyPolicy from './components/PrivacyPolicy';
import DemoTicketTimes from './components/demotickettimes';
import DemoTicketQuality from './components/demoticketquality';
import DemoEasyApp from './components/demoeasyapp';
import './App.css';

function App() {
  const formRef = useRef(null);

  const scrollToForm = () => {
    formRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <Router>
      <div className="flex flex-col min-h-screen">
        <Routes>
          <Route path="/" element={
            <div className="flex flex-col min-h-screen">
              <Banner />
              <div className="mx-auto max-w-screen-xl px-4">
                <VideoUnoAndTextSection />
              </div>
              <div className="mx-auto max-w-screen-xl px-4" ref={formRef}>
                <FormSuscribe />
              </div>
              <Footer />
            </div>
          } />
          <Route path="/terms-and-conditions" element={<TerminosYCondiciones />} />
          <Route path="/subscription" element={<Subscription />} />
          <Route path="/plans" element={<Plans />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/demo-ticket-times" element={<DemoTicketTimes />} />
          <Route path="/demo-ticket-quality" element={<DemoTicketQuality />} />
          <Route path="/demo-easy-app" element={<DemoEasyApp />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;