import React from 'react';
import Banner from './Banner';
import Footer from './Footer';
import { useNavigate } from 'react-router-dom';

function DemoTicketQuality() {
  const navigate = useNavigate();

  return (
    <div className="flex flex-col min-h-screen">
      <Banner />
      
      {/* Sección 1: Video */}
      <section className="w-full bg-white py-16">
        <div className="max-w-screen-xl mx-auto">
          <h2 className="text-3xl font-bold mb-8 text-[#0f2645]">Ticket Quality Demo</h2>
          <div className="w-full aspect-video rounded-2xl overflow-hidden shadow-lg">
            <video 
              className="w-full h-full object-cover"
              controls
              autoPlay={true}
            >
              <source src="/videoticketquality.mp4" type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
        </div>
      </section>

      {/* Sección 6: Cómo funciona */}
      <section className="w-full bg-gray-50 py-16">
        <div className="max-w-screen-xl mx-auto">
          <h2 className="text-3xl font-bold mb-8 text-[#0f2645]">How it Works</h2>
          
          <div className="space-y-8">
            <div className="bg-white p-8 rounded-xl shadow-sm">
              <p className="text-lg text-gray-700 mb-6">
                Enhance your team's quality and efficiency with our advanced ticket analysis solution for Zendesk Support. Ticket Quality provides detailed insights into ticket handling, SLA compliance, and team performance metrics.
              </p>

              <div className="mb-8">
                <h3 className="text-xl font-semibold mb-4 text-[#0f2645]">Key features:</h3>
                <ul className="space-y-3 text-gray-700">
                  <li className="flex items-start">
                    <span className="text-[#ffa100] mr-2">•</span>
                    Analysis of key SLA metrics: first response and full resolution
                  </li>
                  <li className="flex items-start">
                    <span className="text-[#ffa100] mr-2">•</span>
                    Detailed group transfer history with working time for each group
                  </li>
                  <li className="flex items-start">
                    <span className="text-[#ffa100] mr-2">•</span>
                    Business hours analysis
                  </li>
                  <li className="flex items-start">
                    <span className="text-[#ffa100] mr-2">•</span>
                    Analysis of ticket assignment and offering to different agents
                  </li>
                </ul>
              </div>

              <div className="mb-8">
                <p className="text-gray-700 mb-4">
                  Perfect for your team of Administrators, Supervisors, or QA staff.
                </p>
                <p className="text-gray-700 mb-6">
                  Ticket Quality seamlessly integrates with your Zendesk workflow, as it can audit any ticket, whether it's messaging (WhatsApp, Chat Widget, Facebook DM, Instagram DM, XCorp DM, Telegram) or Asynchronous (Email, Web Form, Api, Facebook POST, Instagram POST, XCorp POST) providing immediate information or upon ticket resolution without any additional configuration.
                </p>
              </div>

              <div className="bg-gray-50 p-6 rounded-lg">
                <p className="text-gray-700">
                  For support or inquiries, send us an email at{' '}
                  <a href="mailto:contacto@easyappforz.com" className="text-[#ffa100] hover:underline">
                    contacto@easyappforz.com
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Sección 7: Precios */}
      <section className="w-full bg-white py-16">
        <div className="max-w-screen-xl mx-auto">
          <h2 className="text-3xl font-bold mb-8 text-[#0f2645]">Pricing</h2>
          <div className="bg-gradient-to-r from-blue-50/30 to-transparent p-8 rounded-2xl shadow-sm">
            <div className="flex flex-col md:flex-row justify-between items-start md:items-center gap-6">
              <div>
                <h3 className="text-2xl font-bold text-[#0f2645] mb-2">Free Forever</h3>
                <p className="text-gray-700">No cost, no hidden fees</p>
              </div>
              <div className="flex flex-col md:flex-row gap-6">
                <a 
                  href="https://www.zendesk.com/in/marketplace/apps/support/1101694/ticket-quality/?queryID=2cb3df7d222420058e998070474fd325"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="inline-block w-full md:w-auto px-8 py-3 bg-[#ffa100] text-white font-medium rounded-lg hover:bg-[#ff9100] transition-colors duration-300 text-center"
                >
                  Install
                </a>
                <button 
                  onClick={() => navigate('/#contact')}
                  className="w-full md:w-auto px-8 py-3 bg-[#0f2645] text-white font-medium rounded-lg hover:bg-[#1c3861] transition-colors duration-300"
                >
                  Schedule Demo
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
}

export default DemoTicketQuality; 