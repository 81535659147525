import React from 'react';
import Banner from './Banner';
import Footer from './Footer';
import { useNavigate } from 'react-router-dom';

function DemoTicketTimes() {
  const navigate = useNavigate();

  return (
    <div className="flex flex-col min-h-screen">
      <Banner />
      
      {/* Sección 1: Video */}
      <section className="w-full bg-white py-16">
        <div className="max-w-screen-xl mx-auto">
          <h2 className="text-3xl font-bold mb-8 text-[#0f2645]">Ticket Times Demo</h2>
          <div className="w-full aspect-video rounded-2xl overflow-hidden shadow-lg">
            <video 
              className="w-full h-full object-cover"
              controls
              autoPlay={true}
            >
              <source src="/tickettimesvideo.mp4" type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
        </div>
      </section>

      {/* Sección 6: Cómo funciona */}
      <section className="w-full bg-gray-50 py-16">
        <div className="max-w-screen-xl mx-auto">
          <h2 className="text-3xl font-bold mb-8 text-[#0f2645]">How it Works</h2>
          
          <div className="space-y-8">
            <div className="bg-white p-8 rounded-xl shadow-sm">
              <p className="text-lg text-gray-700 mb-6">
                Control and optimize agent productivity with our comprehensive time tracking solution for Zendesk Support. Ticket Times provides real-time tracking of time spent on tickets by each agent, helping teams improve efficiency and resource allocation.
              </p>

              <div className="mb-8">
                <h3 className="text-xl font-semibold mb-4 text-[#0f2645]">Key features include:</h3>
                <ul className="space-y-3 text-gray-700">
                  <li className="flex items-start">
                    <span className="text-[#ffa100] mr-2">•</span>
                    Automatic time tracking when agents work on tickets
                  </li>
                  <li className="flex items-start">
                    <span className="text-[#ffa100] mr-2">•</span>
                    Real-time timer display with time limits and alerts that you can configure in the admin panel
                  </li>
                  <li className="flex items-start">
                    <span className="text-[#ffa100] mr-2">•</span>
                    Detailed breakdown of Agent ticket entries and their timestamps
                  </li>
                  <li className="flex items-start">
                    <span className="text-[#ffa100] mr-2">•</span>
                    Historical time tracking data for analysis and reporting thanks to the custom time fields used by the app
                  </li>
                  <li className="flex items-start">
                    <span className="text-[#ffa100] mr-2">•</span>
                    Customizable time targets and notifications
                  </li>
                </ul>
              </div>

              <div className="mb-8">
                <h3 className="text-xl font-semibold mb-4 text-[#0f2645]">Perfect for your team if you're looking to:</h3>
                <ul className="space-y-3 text-gray-700">
                  <li className="flex items-start">
                    <span className="text-[#ffa100] mr-2">•</span>
                    Measure and optimize agent productivity
                  </li>
                  <li className="flex items-start">
                    <span className="text-[#ffa100] mr-2">•</span>
                    Identify bottlenecks in ticket resolution
                  </li>
                  <li className="flex items-start">
                    <span className="text-[#ffa100] mr-2">•</span>
                    Make data-driven staffing decisions
                  </li>
                  <li className="flex items-start">
                    <span className="text-[#ffa100] mr-2">•</span>
                    Improve time management and resource allocation
                  </li>
                </ul>
              </div>

              <div className="mb-8">
                <p className="text-gray-700 mb-6">
                  Ticket Times seamlessly integrates with your Zendesk workflow, requiring only minimal setup - create two custom ticket fields: Total Ticket Time and Time Spent On Update to store time records for each agent in the tickets.
                </p>
              </div>

              <div className="bg-gray-50 p-6 rounded-lg">
                <p className="text-gray-700">
                  If you have any questions, you can send us a message at{' '}
                  <a href="mailto:jzabalacx@gmail.com" className="text-[#ffa100] hover:underline">
                    contacto@easyappforz.com
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Sección 7: Precios */}
      <section className="w-full bg-white py-16">
        <div className="max-w-screen-xl mx-auto">
          <h2 className="text-3xl font-bold mb-8 text-[#0f2645]">Pricing</h2>
          <div className="bg-gradient-to-r from-purple-50/30 to-transparent p-8 rounded-2xl shadow-sm">
            <div className="flex flex-col md:flex-row justify-between items-start md:items-center gap-6">
              <div>
                <h3 className="text-2xl font-bold text-[#0f2645] mb-2">Price (USD)</h3>
                <div className="flex items-baseline gap-2">
                  <span className="text-4xl font-bold text-[#0f2645]">$1</span>
                  <span className="text-gray-600">/agent/month</span>
                </div>
              </div>
              <div className="flex flex-col md:flex-row gap-6">
                <a 
                  href="https://www.zendesk.com/in/marketplace/apps/support/1096052/ticket-times/?queryID=ad752eebf06efc1907aaf9eab63a5d75"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="inline-block w-full md:w-auto px-8 py-3 bg-[#ffa100] text-white font-medium rounded-lg hover:bg-[#ff9100] transition-colors duration-300 text-center"
                >
                  Start Free Trial
                </a>
                <button 
                  onClick={() => navigate('/#contact')}
                  className="w-full md:w-auto px-8 py-3 bg-[#0f2645] text-white font-medium rounded-lg hover:bg-[#1c3861] transition-colors duration-300"
                >
                  Schedule Demo
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
}

export default DemoTicketTimes;
